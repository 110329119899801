/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { SelectorOption } from 'hems';
import { Helper } from '@hems/util';

const euCountryList: SelectorOption[] = [
  {
    value: 'DE',
    text: 'code.cntrycd.de',
  },
  {
    value: 'FR',
    text: 'code.cntrycd.fr',
  },
  {
    value: 'GB',
    text: 'code.cntrycd.gb',
  },
  {
    value: 'IE',
    text: 'code.cntrycd.ie',
  },
  {
    value: 'PT',
    text: 'code.cntrycd.pt',
  },
];
const auCountryList: SelectorOption[] = [
  {
    value: 'AU',
    text: 'code.cntrycd.au',
  },
  {
    value: 'NZ',
    text: 'code.cntrycd.nz',
  },
];
const usCountryList: SelectorOption[] = [
  {
    value: 'US',
    text: 'code.cntrycd.us',
  },
];
const krCountryList: SelectorOption[] = [
  {
    value: 'KR',
    text: 'code.cntrycd.kr',
  },
];

export function getCountryList(): SelectorOption[] {
  const region = Helper.getEnvLocale();
  switch (region) {
    case 'EU':
      return euCountryList;
    case 'AU':
      return auCountryList;
    case 'US':
      return usCountryList;
    default:
      return euCountryList.concat(auCountryList).concat(usCountryList).concat(krCountryList);
  }
}
