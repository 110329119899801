import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginPopup = _resolveComponent("LoginPopup")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_PromptPopup = _resolveComponent("PromptPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoginPopup, {
      onLogin: _ctx.onLogin,
      onClose: _ctx.closePopup,
      onJoin: _cache[0] || (_cache[0] = () => _ctx.$emit('join')),
      onFindIdPw: _cache[1] || (_cache[1] = (type) => _ctx.$emit('findIdPw', type)),
      onGoogleLogin: _ctx.googleLogin,
      onFacebookLogin: _ctx.facebookLogin,
      onAppleLogin: _ctx.appleLogin
    }, null, 8, ["onLogin", "onClose", "onGoogleLogin", "onFacebookLogin", "onAppleLogin"]),
    _createVNode(_component_PromptPopup, {
      title: _ctx.$t('common.country'),
      "show-close": false,
      "confirm-button-text": _ctx.$t('common.save'),
      "close-button-text": _ctx.$t('common.close')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Selector, {
          modelValue: _ctx.countryState.countryCode,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.countryState.countryCode) = $event)),
          label: _ctx.$t('message.select_your_country'),
          name: "countryCode",
          options: _ctx.countryState.countryList
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _: 1
    }, 8, ["title", "confirm-button-text", "close-button-text"])
  ], 64))
}